import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Layout, message, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useMutation /*, useQuery*/ } from '@apollo/client';
import { useIntercom } from 'react-use-intercom';
import PropTypes from 'prop-types';
import moment from 'moment';
import html2canvas from 'html2canvas';

import {
  HeaderSection,
  SimpleSection,
  SectionWrapper,
  SignatureSection,
  ZoomHandler,
} from './components';
import {
  defaultHebrewTitleSettings,
  defaultHebrewSubTitleSettings,
  defaultHebrewBodySettings,
} from 'pages/Proposal/constants/constants';
import { DownloadingPDFModal } from '../Modals';

import { ADD_LIBRARY_SECTION } from 'graphql/mutations/contentLibraryMutations';
import { ADD_PROPOSAL_ATTACHMENTS } from 'graphql/mutations/proposalMutation';
import { PROPOSAL_STATUS, PROPOSAL_SUBSCRIPTION_TOPIC } from 'constants/index';
// import { POLLING_INTERVAL } from 'constants/index';
// import { PING_POLLING } from 'graphql/queries/pollingQueries';
import helpers, { getProposalTitle, scrollToSection } from 'helpers/proposal';

import useWindowDimensions from 'hooks/useWindowDimensions';
import Loader from 'components/Loader';
import FileCopier from 'components/FileCopier';
import Uploader from 'components/Uploader';
import DraftContext from 'context/DraftContext';

import ApprovedProposalModal from '../Modals/ApprovedProposalModal';
import DraftModals from './DraftModals/DraftModals';
import ProposalHeaderBar from '../ProposalHeaderBar';
import ProposalWixHeaderBar from '../ProposalWixHeaderBar';
import ToolBarSideMenu from '../ToolBarSideMenu/ToolBarSideMenu';
import utils from './utils';
import ContentTable from '../ContentTable/ContentTable';
import AttachmentSection from './components/AttachmentSection/AttachmentSection';

import './Draft.scss';
import { useProposalContext } from 'context/proposal';

const Draft = (props) => {
  const history = useHistory();
  const { trackEvent } = useIntercom();

  const {
    config,
    saveProposalDraft,
    user,
    saveUser,
    isTemplate,
    isSection,
    wixProductsAndServices,
    wixSiteProperties,
    fetchMoreWixProductsAndServicesLoading,
    fetchMoreWixProductsAndServices,
    path,
    setOpenVariablesList,
    openVariablesList,
    isHeader,
    configText,
    openWixPreview,
    setOpenWixPreview,
    addItem,
  } = props;

  const proposalRef = useRef();
  const unsubscribe = useRef();
  const librarySectionRef = useRef();
  const clickCounterRef = useRef(0);
  const listenerRef = useRef(false);
  //TUDO: have to remove
  const {
    subscribeToUpdateProposal,
    tabID,
    setIsEditingModal,
    isEditingModal,
    downloadingPDF,
    proposal: prop,
    propRef,
    wixEditor,
    savingDraftProposal,
    setSavingDraftProposal,
    scrollingTo,
    setScrollingTo,
    handleUpdateTemplateProposalSection: updateProposal,
    saveTemplateProposal: saveProposal,
    // handleProposalUndoRedo: handleUndoRedo,
    showLoader: showLoaderProp,
    updateLoaderState,
    // shouldUndoRedoUpdate,
    templateWixEditor,
  } = useProposalContext();

  // const [isPolling, setIsPolling] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [activeSideMenuKey, setActiveSideMenuKey] = useState('elements');
  const [libraryType, setLibraryType] = useState(!isSection ? 'covers' : 'media');
  const [element, setElement] = useState('');
  const [elementValue, setElementValue] = useState('');
  const [confirmModal, showConfirmModal] = useState(false);
  const [attachmentModal, showAttachmentModal] = useState(false);
  const [reOrderSectionModal, showReOrderSectionModal] = useState(false);
  const [importSectionModal, showImportSectionModal] = useState(false);
  const [selectedSectionName, setSelectedSectionName] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [showColumnOptions, setShowColumnOptions] = useState(null);
  const [showAddToLibrary, setShowAddToLibrary] = useState(null);
  const [lastSectionOrder, setLastSectionOrder] = useState([]);
  const [activeEditorId, setActiveEditorId] = useState(null);

  // store id of section last hovered, so that we can scroll and reach that section while dragging sections
  const [lastHoveredSection, setLastHoveredSection] = useState(null);
  // height of all new section placeholder, to minus, so that we can go to original scroll position
  const [newSectionsHeight, setNewSectionsHeight] = useState(null);

  const [elementsInSection, setElementsInSection] = useState({});
  const [draggingElement, setDraggingElement] = useState(null);
  const [droppable, setDroppable] = useState(false);
  const [coverDroppable, setCoverDroppable] = useState(false);
  const [sectionDroppable, setSectionDroppable] = useState(false);
  const [sectionElementDroppable, setSectionElementDroppable] = useState(false);
  const [zoomValue, setZoomValue] = useState(100);
  const [downloadPDFError, setDownloadPDFError] = useState('');
  const [collapse, setCollapse] = useState(false);
  const [collapseContentTable, setCollapseContentTable] = useState(true);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [openPreview, setOpenPreview] = useState(null);
  const [sectionOrderArrangement, setSectionOrderArrangement] = useState(
    prop?.draft?.sectionorder || []
  );
  const [disablePreview, setDisablePreview] = useState(false);
  const [dropDisabled, setDropDisabled] = useState('');
  const [draggingElementIndex, setDraggingElementIndex] = useState(null);
  const [keyboardModal, setKeyboardModal] = useState(false);
  const [askAI, setAskAI] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState(false);

  const [copy, copyStatus] = FileCopier();
  const [addAttachment] = useMutation(ADD_PROPOSAL_ATTACHMENTS);

  const { width } = useWindowDimensions();
  const proposalEditEvent = localStorage.getItem('editEvent');

  const isRtl =
    prop?.language?.toLowerCase() === 'hebrew' || prop?.language?.toLowerCase() === 'arabic';
  const [upload] = Uploader();

  const [addLibrarySection] = useMutation(ADD_LIBRARY_SECTION, {
    onCompleted: async () => {
      if (librarySectionRef && librarySectionRef.current) {
        await librarySectionRef.current.reloadItems();
      }
    },
  });

  const delegate = (el, evt, sel, handler) => {
    el.addEventListener(evt, function (event) {
      var t = event.target;
      while (t && t !== this) {
        if (t instanceof Element && t.matches(sel)) {
          handler.call(t, event);
        }
        t = t.parentNode;
      }
    });
  };

  const logEditing = useCallback(
    (status, prop, user, event) => {
      if (user && prop && !isSection) {
        updateProposal({
          id: prop._id,
          _id: prop._id,
          pid: prop.pid,
          uid: prop.uid,
          auid: prop.auid,
          isEditing: status
            ? {
                uid: user._id,
                name: user.profile.name,
                tabID: sessionStorage.tabID,
              }
            : null,
          editEvent: event,
          channel: prop.channel,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleManualSave = useCallback(() => {
    setSavingDraftProposal(true);
    saveProposal(propRef.current, () => setSavingDraftProposal(false));
  }, [propRef, saveProposal, setSavingDraftProposal]);

  const keydownHandler = useCallback((e) => {
    if (
      (e.keyCode === 90 && (e.ctrlKey || e.metaKey) && e.shiftKey) ||
      (e.keyCode === 89 && (e.ctrlKey || e.metaKey))
    ) {
      e.preventDefault();
      // handleUndoRedo('REDO');
      return true;
    } else if (e.keyCode === 90 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      // handleUndoRedo('UNDO');
      return true;
    } else if (e.keyCode === 83 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      // handleManualSave();
      return true;
    }
  }, []);

  useEffect(() => {
    if (disablePreview && !savingDraftProposal) {
      setDisablePreview(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingDraftProposal]);

  useEffect(() => {
    try {
      unsubscribe.current = subscribeToUpdateProposal();
    } catch (error) {
      console.log(error);
    }

    if (!listenerRef.current) {
      listenerRef.current = true;
      document.addEventListener('keydown', keydownHandler);
    }

    localStorage.setItem('editEvent', null);
    return () => {
      unsubscribe && unsubscribe.current();
      document.removeEventListener('keydown', () => {
        listenerRef.current = false;
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (width < 1300) {
      setCollapse(true);
      setCollapseContentTable(true);
    } else {
      if (collapse) {
        // screen >= 1300 if collapsed then expand
        setCollapse(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    window.ga('send', 'event', 'new-edit', 'init', window.location.href);
    if (!prop?.wixProposalId) {
      trackEvent('new-edit', {
        pid: prop?.pid,
        id: prop?._id,
      });
    }

    delegate(document, 'click', '.simple-section-content .rich-editor a', function (event) {
      event.preventDefault();
      var url = event.target.closest('.simple-section-content .rich-editor a').getAttribute('href');

      if (
        url &&
        url.indexOf('http') !== 0 &&
        url.indexOf('tel') === -1 &&
        url.indexOf('mailto') === -1
      ) {
        url = 'http://' + url;
      }
      url && window.open(url) && event.preventDefault();
    });

    document.body.classList.add('draft-body');

    return () => {
      document.body.classList.remove('draft-body');
      document.removeEventListener('click', () => {});
      const edited = JSON.parse(proposalEditEvent);
      if (proposalRef?.current && proposalRef?.current?.uid === user?._id && !isTemplate) {
        const editHandler = () => {
          logEditing(false, proposalRef?.current, user, edited);
        };
        editHandler();
        window.removeEventListener('onbeforeunload', editHandler);
        window.removeEventListener('onunload', editHandler);
      }
    };
  }, []); // eslint-disable-line

  /*
   * Replace polling with websockets
   *
  const {
    data: pollingData,
    // startPolling,
    // stopPolling,
  } = useQuery(PING_POLLING, {
    variables: {
      from: 'Draft',
      proposalId: prop._id,
      userId: user?._id || '',
      teamId: user?.teamId || user?._id || '',
    },
    fetchPolicy: 'no-cache',
    pollInterval: POLLING_INTERVAL.proposal * 1000,
    onError: (pollingErrorResponse) => {
      console.error('error in polling', pollingErrorResponse);
    },
  });

  useEffect(() => {
    if (pollingData) {
      let typename,
        proposal = undefined;
      typename = pollingData?.pingPolling?.typename;
      proposal = pollingData?.pingPolling?.proposal;
      if (typename && typename !== 'pong') {
        if (prop?._id === proposal?._id) {
          setProp({ ...prop, isEditing: proposal.isEditing });
          proposalRef.current = { ...prop, isEditing: proposal.isEditing };
        }
      }
    }
  }, [setProp, pollingData]); // eslint-disable-line
  */

  useEffect(() => {
    document.title = 'Prospero - Proposal';
  });

  /**
   * No manual start and stop polling 
   *
  useEffect(() => {
    if (sessionStorage.getItem('shouldPoll') === 'true') {
      if (prop?.isEditing?.uid !== user?._id && isPolling) {
        setIsPolling(false);
        stopPolling();
      } else if (prop?.isEditing?.uid === user?._id && !isPolling) {
        setIsPolling(true);
        startPolling(POLLING_INTERVAL.proposal * 1000);
      } else if (!props?.shouldPoll) {
        sessionStorage.setItem('shouldPoll', false);
        stopPolling();
      }
    } else {
      if (prop?.isEditing?.uid !== user?._id && isPolling) {
        setIsPolling(false);
        stopPolling();
      } else if (prop?.isEditing?.uid === user?._id && !isPolling) {
        setIsPolling(true);
        startPolling(POLLING_INTERVAL.proposal * 1000);
      } else if (props?.shouldPoll) {
        sessionStorage.setItem('shouldPoll', true);
        startPolling(POLLING_INTERVAL.proposal * 1000);
      }
    }
  }, [setIsPolling, isPolling, props, startPolling, stopPolling, prop, user]);
  */

  useEffect(() => {
    const updatedProposal = Object.assign({}, JSON.parse(JSON.stringify(prop)));

    // Sanitize draft terms here, add or remove expiry date variable
    if (prop && prop.fromUserTemplate && !isSection && !isTemplate) {
      setSavingDraftProposal(true);
      saveProposal({ fromUserTemplate: null, draft: prop.draft }, () =>
        setSavingDraftProposal(false)
      );
    }
    // Check draft  is exist in the proposal. If not create all sections
    if (prop && !prop.draft && !savingDraftProposal && !isTemplate) {
      setSavingDraftProposal(true);
      const whyme2 = utils.getWhyMe({ prop, configText });
      const sectionorder = [...helpers.defaultSectionOrder];

      if (!Object.keys(whyme2)?.length) {
        sectionorder.splice(sectionorder.indexOf('whyme2'), 1);
      }

      // Create sectionorders
      const draft = {
        header: utils.getHeader({
          proposal: prop,
          user,
          configText,
        }),
        overview: utils.getOverview({ prop, configText }),
        whyme2,
        pricing2: utils.getPricing({ prop, configText }),
        milestones2: utils.getMilestones({ prop, configText }),
        terms: utils.getTerms({ prop, configText, user }),
        whatsnext: utils.getWhatsNext({ configText }),
        signature: utils.getSignature({
          prop,
          user,
          configText,
        }),
        sectionorder,
      };

      if (
        prop?.language?.toLowerCase() === 'hebrew' ||
        prop?.language?.toLowerCase() === 'arabic'
      ) {
        draft.bodyFont = defaultHebrewBodySettings;
        draft.titleFont = defaultHebrewTitleSettings;
        draft.subTitleFont = defaultHebrewSubTitleSettings;
      }

      const isEditing = {
        uid: user._id,
        name: user.profile.name,
        tabID,
      };

      // Update title, subtitle, body texts if the language is hebrew
      saveProposal({ isEditing, draft }, () => setSavingDraftProposal(false));
    } else if (prop && prop.draft && !savingDraftProposal) {
      if ((!prop.draft.signature || !prop.draft.sectionorder) && !isSection && !isTemplate) {
        setSavingDraftProposal(true);
        if (prop?.importedProposal) {
          updatedProposal.draft = {
            header:
              prop?.draft?.header ||
              utils.getHeader({
                proposal: prop,
                user,
                configText,
              }),
            signature:
              prop?.draft?.signature ||
              utils.getSignature({
                prop,
                user,
                configText,
              }),
            sectionorder: prop?.draft?.sectionorder || prop?.draft?.sectionorder,
          };
        } else {
          const whyme2 = prop?.draft?.whyme2 || utils.getWhyMe({ prop, configText });
          const sectionorder = prop?.draft?.sectionorder || [...helpers.defaultSectionOrder];

          if (!Object.keys(whyme2)?.length) {
            sectionorder.splice(sectionorder.indexOf('whyme2'), 1);
          }

          updatedProposal.draft = {
            header:
              prop?.draft?.header ||
              utils.getHeader({
                proposal: prop,
                user,
                configText,
              }),
            overview: prop?.draft?.overview || utils.getOverview({ prop, configText }),
            whyme2,
            pricing2: prop?.draft?.pricing2 || utils.getPricing({ prop, configText }),
            milestones2: prop?.draft?.milestones2 || utils.getMilestones({ prop, configText }),
            terms: prop?.draft?.terms || utils.getTerms({ prop, configText, user }),
            whatsnext: prop?.draft?.whatsnext || utils.getWhatsNext({ configText }),
            signature:
              prop?.draft?.signature ||
              utils.getSignature({
                prop,
                user,
                configText,
              }),
            sectionorder,
          };
        }
        if (
          prop?.language?.toLowerCase() === 'hebrew' ||
          prop?.language?.toLowerCase() === 'arabic'
        ) {
          updatedProposal.draft.bodyFont = defaultHebrewBodySettings;
          updatedProposal.draft.titleFont = prop?.importedProposal
            ? { ...defaultHebrewTitleSettings, position: 'none' }
            : defaultHebrewTitleSettings;
          updatedProposal.draft.subTitleFont = defaultHebrewSubTitleSettings;
        }

        updatedProposal.isEditing = {
          uid: user._id,
          name: user.profile.name,
          tabID,
        };

        // Update title, subtitle, body texts if the language is hebrew
        saveProposal(updatedProposal, () => setSavingDraftProposal(false));
      }
    }
  }, [savingDraftProposal]); //eslint-disable-line

  useEffect(() => {
    // if (
    //   !proposalRef.current &&
    //   ((prop?.draft?.imported && prop?.importedProposal) || !prop?.importedProposal)
    // ) {
    proposalRef.current = prop;
    // }

    const updatedProposal = proposalRef.current;

    // check for team member editing and show/close modal
    if ((updatedProposal?.auid && !isTemplate) || wixEditor || templateWixEditor) {
      if (
        updatedProposal?.isEditing &&
        updatedProposal?.isEditing?.tabID &&
        updatedProposal?.isEditing?.tabID !== tabID
      ) {
        setIsEditingModal('user');
      } else if (updatedProposal?.isEditing && updatedProposal?.isEditing?.uid !== user?._id) {
        setIsEditingModal('team');
      } else if (!updatedProposal?.isEditing) {
        if (isEditingModal) {
          setIsEditingModal(false);
        }

        logEditing(true, updatedProposal, user);
        window.onunload = window.onbeforeunload = (event) => {
          if (proposalRef.current?.isEditing?.uid === user._id) {
            logEditing(false, proposalRef.current, user);
          }
          (event || window.event).returnValue = null;
          return null;
        };
      } else if (updatedProposal?.isEditing?.uid === user._id && isEditingModal) {
        setIsEditingModal(false);
      }
    }

    // Check for duplicate tab and show/close modal
    if (
      ((updatedProposal?.draft?.imported && updatedProposal?.importedProposal) ||
        (updatedProposal && !updatedProposal?.importedProposal)) &&
      !savingDraftProposal &&
      tabID &&
      !isTemplate &&
      !wixEditor &&
      !templateWixEditor
    ) {
      if (
        updatedProposal?.isEditing &&
        updatedProposal?.isEditing?.tabID &&
        updatedProposal?.isEditing?.tabID !== tabID
      ) {
        setIsEditingModal('user');
      } else if (!updatedProposal?.isEditing) {
        if (isEditingModal) {
          setIsEditingModal(false);
        }
        logEditing(true, updatedProposal, user);

        window.onunload = window.onbeforeunload = (event) => {
          if (proposalRef.current?.isEditing?.tabID === tabID) {
            logEditing(false, proposalRef.current, user);
          }
          (event || window.event).returnValue = null;
          return null;
        };
      } else if (!!tabID && updatedProposal?.isEditing?.tabID === tabID && isEditingModal) {
        setIsEditingModal(false);
      }
    }
  }, [prop?.isEditing]); //eslint-disable-line

  useEffect(() => {
    if (openPreview && !savingDraftProposal && !disablePreview) {
      setIsLoading(false);
      setOpenPreview(false);

      let propPreviewUrl = '/pd/' + prop?._id;

      if (isTemplate) {
        history.push(`/pt/${prop._id}`);
      } else if (isSection) {
        history.push(`/ps/${prop?.draft?.sectionorder[0]}`);
      } else {
        history.push(propPreviewUrl);
      }
    }
  }, [openPreview, savingDraftProposal, disablePreview]); // eslint-disable-line

  useEffect(() => {
    document.body.classList.remove('.ndfHFb-c4YZDc-Wrql6b');
  }, []); //eslint-disable-line

  useEffect(() => {
    /*
    REMARKS: 
    while dragging and dropping sections from sidebar "drop your section" 
    placeholders are added to page which causes user loss the original 
    scroll position
    with this we are trying to make the experience better by taking user 
    to his original scroll position
    also if a section is large we take him to bottom of the section to drop the section
    */

    const mainEl = document.getElementById('editor-wrapper');
    const newsectionEl = document.querySelectorAll('.new-section');
    const simpleSections = document.querySelectorAll('.simple-section');

    if (sectionDroppable && lastHoveredSection && newsectionEl?.length && simpleSections?.length) {
      let index = 0;
      let activeSection = null;
      let activeSectionHeight = 0;

      Array.from(simpleSections).forEach((div, i) => {
        if (div.id === lastHoveredSection) {
          index = i;
          activeSection = div;
          activeSectionHeight = div.getBoundingClientRect().height;
        }
      });

      // page will be scrolled back to this position if drag cancelled
      setNewSectionsHeight(mainEl.scrollTop);

      // if section height > screen height then go to bottom of current section
      if (index !== 0 && activeSectionHeight > window.innerHeight - 140) {
        // scroll to current section
        activeSection.scrollIntoView({ behavior: 'instant' });

        // scroll to bottom of current section
        const scrollHeight = activeSectionHeight - 140 - 200;

        setTimeout(() => {
          mainEl.scrollBy({ top: scrollHeight, behavior: 'smooth' });
        }, 10);
      } else {
        const newSectionHeight = newsectionEl[0].getBoundingClientRect().height || 0;
        const scrollBy = (index + 1) * newSectionHeight;

        mainEl.scrollBy({ top: scrollBy, behavior: 'instant' });
      }
    } else if (!sectionDroppable && newSectionsHeight) {
      // when dropable is canceled, scroll to original position
      mainEl.scrollTo({ top: newSectionsHeight, behavior: 'instant' });
    }
  }, [sectionDroppable]); //eslint-disable-line

  const openPublishView = (openPreviewValue) => {
    window.ga('send', 'event', 'click', 'click-preview', window.location.href);
    window.ga('send', 'pageview', '/event/click-preview');

    let url = window.location.origin + '/pd/' + prop?._id;

    if (!prop?.wixProposalId) {
      trackEvent('click-client-preview', {
        pid: prop?.pid,
        id: prop?._id,
        url,
      });
    }

    if (!wixEditor && !templateWixEditor) {
      setIsLoading(true);
      setOpenPreview(true);
    } else if (!openWixPreview && openPreviewValue) {
      setIsLoading(true);
      setOpenWixPreview(openPreviewValue);
    }
  };

  const changeToolBarSideMenu = ({ key, type }) => {
    setActiveSideMenuKey(key);
    setLibraryType(type || (isSection ? 'media' : 'covers'));
  };

  if (!prop.draft) return <Loader />;

  const { state } = prop;

  if (state === 'approved' && !isTemplate) {
    const approvedDate = helpers.getApprovedDate({ proposal: prop });
    const approvedDateValue = approvedDate
      ? ` on ${moment(approvedDate).format(user?.profile?.dateFormat || 'MM/DD/YYYY')} `
      : ' ';

    return !downloadingPDF ? (
      <ApprovedProposalModal
        approvedDateValue={approvedDateValue}
        downloadingPDF={downloadingPDF}
        wixEditor={wixEditor}
        templateWixEditor={templateWixEditor}
        openPublishView={openPublishView}
      />
    ) : (
      <DownloadingPDFModal />
    );
  }

  const saveLastSectionOrder = (value) => {
    let tempLastSectionOrder = lastSectionOrder;
    tempLastSectionOrder.push(value);
    setLastSectionOrder(tempLastSectionOrder);
  };

  const handleToolbarElements = (e, type, value) => {
    setElement('');
    if (type === 'add-media-image' && !selectedBlock) return;
    e.preventDefault();
    clickCounterRef.current = type;
    setElement(type);
    setElementValue(value);
  };

  const dragStart = (ev, data = null) => {
    const node = ev.currentTarget;

    const overlayDiv = document.createElement('div');
    overlayDiv.innerHTML = 'Drop item in proposal';
    overlayDiv.className = 'drag-element-placeholder';

    var crt = node.cloneNode(true);
    // Removing the move icon
    for (let i = 0; i < crt.children.length; i++) {
      if (crt.children[i].nodeName === 'SPAN') {
        crt.removeChild(crt.children[i]);
        i = crt.children.length;
      }
    }
    crt.style.width = '100%';
    crt.style.maxWidth = '40em';
    crt.className += 'drag-element-placeholder';
    crt.style.position = 'absolute';
    crt.style.top = '0';
    crt.style.left = '0';
    crt.style.zIndex = '-1';
    ev.currentTarget.appendChild(overlayDiv);
    ev.currentTarget.appendChild(crt);
    // Adding a custom Ghost Image
    ev.dataTransfer.setDragImage(crt, 0, 0);
    ev.dataTransfer.dropEffect = 'move';
    ev.dataTransfer.setData('text', ev.target.id);
    setDraggingElement(ev.target.id);
    if (ev.target.id === 'add-attachment') {
      setDroppable(true);
    } else if (ev.target.id === 'add-price') {
      setDraggingElement('add-price');
    } else if (ev.target.id.startsWith('toolbar-library-cover-')) {
      setCoverDroppable(true);
      ev.dataTransfer.setData('img', ev.target.dataset.img);
    } else if (ev.target.id.startsWith('toolbar-library-media-')) {
      ev.dataTransfer.setData('src', ev.target.dataset.src);
    } else if (ev.target.id.startsWith('toolbar-library-attachment-media-')) {
      setDroppable(true);
      ev.dataTransfer.setData('media', ev.target.dataset.media);
    } else if (ev.target.id.startsWith('toolbar-library-section-')) {
      setSectionDroppable(true);
      ev.dataTransfer.setData('section', ev.target.dataset.section);
    } else if (prop?.importedProposal && ev.target.id.startsWith('add-')) {
      // seperate add element for imported proopsal
      setSectionElementDroppable(true);
      ev.dataTransfer.setData('sectionElement', ev.target.id);
    }
  };

  const dragEnd = (ev) => {
    // Removing the custom element added ad Ghost Image on dragStart
    ev.currentTarget.removeChild(ev.currentTarget.lastElementChild);
    ev.currentTarget.removeChild(ev.currentTarget.lastElementChild);
    setDroppable(false);
    setCoverDroppable(false);
    setSectionDroppable(false);
    setSectionElementDroppable(false);
    setDraggingElement(false);
  };

  const duplicateSection = ({ sectionName, position }) => {
    const selectedSection = prop.draft[sectionName];

    // Generate unique section id
    const sectionId = uuidv4();
    if (prop) {
      let newsection = JSON.parse(JSON.stringify(selectedSection));
      const sectionOrder = helpers.findSectionOrder({
        proposal: prop,
        sectionId,
        sectionName,
        position,
      });

      saveProposal(
        {
          'draft.sectionorder': sectionOrder,
          [`draft.${sectionId}`]: newsection,
        },
        ({ data }) => {
          if (data) {
            scrollToSection({ sectionId });
            saveLastSectionOrder(sectionOrder);
            saveLastSectionOrder(false);
            setSectionOrderArrangement(sectionOrder);
          }
        }
      );
    }
  };

  const addNewSection = ({ sectionName, titlePosition, position }) => {
    const sectionId = uuidv4();
    const newsection = {
      title: '<div></div>',
      text: '<div></div>',
      subtitle: '<div></div>',
      titlePosition,
    };

    const sectionOrder = helpers.findSectionOrder({
      proposal: prop,
      sectionId,
      sectionName,
      position,
    });

    const updateData = {
      'draft.sectionorder': sectionOrder,
      [`draft.${sectionId}`]: newsection,
    };

    if (prop?.importedProposal) {
      updateData['draft.titleFont'] = {
        ...prop?.draft?.titleFont,
        contact: 'block',
        cover: 'top',
        coverTextAlignment: 'left',
        position: 'none',
      };
    }

    updateLoaderState(true);
    saveProposal(updateData, ({ data }) => {
      if (data) {
        updateLoaderState(false);
        scrollToSection({ sectionId });
        saveLastSectionOrder(sectionOrder);
        saveLastSectionOrder(false);
      }
    });
  };

  const addNewSectionElement = async (sectionName, position, element, data = null) => {
    let newsection;
    if (element === 'add-table') {
      newsection = utils.getSectionElements.handleInsertTable();
    } else if (element === 'add-price') {
      newsection = await utils.getSectionElements.handleInsertPriceMiles('price');
    } else if (element === 'add-goals') {
      newsection = await utils.getSectionElements.handleInsertPriceMiles('miles');
    } else if (element === 'add-testimonial') {
      newsection = utils.getSectionElements.handleInsertTestimonial();
    } else if (element === 'add-html') {
      newsection = utils.getSectionElements.handleInsertHTML();
    } else if (element === 'add-form') {
      newsection = await utils.getSectionElements.handleInsertForm(prop);
    } else if (element === 'add-divider') {
      newsection = await utils.getSectionElements.handleInsertDivider();
    } else if (element === 'add-text') {
      newsection = utils.getSectionElements.handleInsertText();
    } else if (element === 'add-qoute') {
      newsection = utils.getSectionElements.handleInsertBlockQuote();
    } else if (element === 'add-gallery') {
      newsection = utils.getSectionElements.handleInsertGallery();
    } else if (element === 'add-image') {
      newsection = await utils.getSectionElements.handleInsertImage(data);
    } else if (element === 'add-video') {
      newsection = await utils.getSectionElements.handleInsertVideo(data);
    }

    const sectionId = uuidv4();

    const sectionOrder = helpers.findSectionOrder({
      proposal: prop,
      sectionId,
      sectionName,
      position,
    });

    let sectionTitleFont = {
      contact: 'block',
      cover: 'top',
      coverTextAlignment: 'left',
      position: prop?.importedProposal ? 'none' : 'normal',
    };

    updateLoaderState(true);
    saveProposal(
      {
        'draft.sectionorder': sectionOrder,
        [`draft.${sectionId}`]: newsection,
        'draft.titleFont': sectionTitleFont,
      },
      ({ data }) => {
        if (data) {
          updateLoaderState(false);
          scrollToSection({ sectionId });
          saveLastSectionOrder(sectionOrder);
          saveLastSectionOrder(false);
          setSectionOrderArrangement(sectionOrder);
        }
      }
    );
  };

  const addNewSectionFromLibrary = ({
    sectionName,
    position,
    section: {
      raw,
      raw1,
      raw2,
      raw3,
      rawtitle,
      subrawtitle,
      text,
      title,
      subtitle,
      image,
      numberOfColumns,
      typeOfColumns,
      titlePosition,
      sectionStyles,
    },
  }) => {
    const sectionId = uuidv4();

    const newsection = {
      subtitle,
      raw,
      raw1,
      raw2,
      raw3,
      subrawtitle,
      text,
      title,
      rawtitle,
      image,
      numberOfColumns,
      typeOfColumns,
      titlePosition,
    };

    const sectionOrder = helpers.findSectionOrder({
      proposal: prop,
      sectionId,
      sectionName: sectionName || selectedSectionName,
      position: position || selectedPosition,
    });

    const updatedSectionStyles = helpers.updateSectionStyles({
      prop,
      sectionId,
      sectionStyle: sectionStyles,
    });

    updateLoaderState(true);
    saveProposal(
      {
        'draft.sectionorder': sectionOrder,
        'draft.sectionStyles': updatedSectionStyles,
        [`draft.${sectionId}`]: newsection,
      },
      ({ data }) => {
        if (data) {
          updateLoaderState(false);
          scrollToSection({ sectionId });
          saveLastSectionOrder(sectionOrder);
          saveLastSectionOrder(false);
          setSectionOrderArrangement(sectionOrder);
        }
      }
    );
  };

  const addFullWidthImageSection = ({ sectionName, position }) => {
    const sectionId = uuidv4();
    const newsection = { image: '<img />' };

    const sectionOrder = helpers.findSectionOrder({
      proposal: prop,
      sectionId,
      sectionName,
      position,
    });
    updateLoaderState(true);
    saveProposal(
      {
        'draft.sectionorder': sectionOrder,
        [`draft.${sectionId}`]: newsection,
      },
      ({ data }) => {
        if (data) {
          updateLoaderState(false);
          scrollToSection({ sectionId });
          saveLastSectionOrder(sectionOrder);
          saveLastSectionOrder(false);
        }
      }
    );
  };

  const changeSectionPosition = ({ sectionName, type }) => {
    const sectionorder = prop.draft.sectionorder || helpers.defaultSectionOrder;

    const fromIndex = sectionorder.findIndex((item) => item === sectionName);

    const updatedOrder = helpers.moveSectionOrder({
      sectionorder,
      fromIndex,
      toIndex: type === 'down' ? fromIndex + 1 : fromIndex - 1,
    });

    saveProposal(
      {
        'draft.sectionorder': updatedOrder,
      },
      ({ data }) => {
        if (data) {
          scrollToSection({ sectionId: sectionName });
          saveLastSectionOrder(updatedOrder);
          saveLastSectionOrder(false);
          setSectionOrderArrangement(updatedOrder);
        }
      }
    );
  };

  const onClickSectionWrapperItem = ({
    actionType,
    titlePosition,
    sectionName,
    position,
    section,
  }) => {
    if (actionType === 'duplicate') {
      duplicateSection({ sectionName, position });
    } else if (actionType === 'deleteSection') {
      setSelectedSectionName(sectionName);
      showConfirmModal(true);
    } else if (actionType === 'addToLibrary') {
      setSelectedSectionName(sectionName);
      const showAddToLibraryValue = showAddToLibrary === sectionName ? '' : sectionName;
      setShowAddToLibrary(showAddToLibraryValue);
    } else if (actionType === 'addNewSection') {
      addNewSection({ sectionName, titlePosition, position });
    } else if (actionType === 'addNewSectionFromLibrary') {
      addNewSectionFromLibrary({ sectionName, position, section });
    } else if (actionType === 'addFullWidthImage') {
      addFullWidthImageSection({ sectionName, position });
    } else if (actionType === 'importSection') {
      setSelectedSectionName(sectionName);
      setSelectedPosition(position);
      showImportSectionModal(true);
    } else if (actionType === 'moveDown') {
      changeSectionPosition({ sectionName, position, type: 'down' });
    } else if (actionType === 'moveUp') {
      changeSectionPosition({ sectionName, position, type: 'up' });
    } else if (actionType === 'addFromLibrary') {
      setSelectedSectionName(sectionName);
      setSelectedPosition(position);
      changeToolBarSideMenu({ key: 'library', type: 'sections' });
    } else if (actionType === 'reOrder') {
      showReOrderSectionModal(true);
    } else if (actionType === 'columns') {
      const columnOptionValue = showColumnOptions === sectionName ? '' : sectionName;
      setShowColumnOptions(columnOptionValue);
    } else if (actionType === 'addNewSectionElement') {
      addNewSectionElement(sectionName, position, section);
    }
  };

  const setProposalZoom = (value) => {
    setZoomValue(value);
  };

  const updateColumnInSection = (sectionName, numberOfColumns, typeOfColumns) => {
    const updatedSection = Object.assign(
      { ...prop.draft[sectionName] },
      {
        numberOfColumns,
        typeOfColumns,
      }
    );
    saveProposal({
      [`draft.${sectionName}`]: updatedSection,
    });
  };

  const updateTitlePositionSection = (sectionName, titlePosition) => {
    const updatedSection = Object.assign(
      { ...prop.draft[sectionName] },
      {
        titlePosition,
      }
    );
    saveProposal({
      [`draft.${sectionName}`]: updatedSection,
    });
  };

  const takeOverProposal = () => {
    logEditing(true, prop, user);
    // setIsEditingModal(false);
  };

  const saveSectionToLibrary = async ({
    sectionName,
    newSectionName,
    selectedFolderKey,
    language,
    isHeader,
  }) => {
    const selectedSection = prop?.draft[sectionName];
    let newsection = JSON.parse(JSON.stringify(selectedSection));
    const sectionObject = { ...newsection };

    sectionObject.libraryTitle = newSectionName;
    sectionObject.uid = user._id;
    sectionObject.createdAt = new Date();
    if (prop?.draft?.titleFont) {
      sectionObject.titleFont = prop.draft.titleFont;
    }

    if (user.teamId) sectionObject.auid = user.teamId;

    delete sectionObject._id;
    delete sectionObject.id;
    delete sectionObject.__v;
    delete sectionObject.importProposalFileType;
    delete sectionObject.importProposalFile;
    delete sectionObject.thumbnail;
    delete sectionObject.originalState;

    if (typeof sectionObject.hidecover === 'string') {
      sectionObject.hidecover = sectionObject.hidecover === 'true';
    }

    const thumbnailUrl = await createSectionThumbnail(sectionName);
    if (thumbnailUrl) {
      sectionObject.thumbnail = thumbnailUrl;
    }

    await addLibrarySection({
      variables: {
        language: language || 'english',
        section: sectionObject,
        folderKey: selectedFolderKey,
        isHeader,
      },
    });

    setShowAddToLibrary('');
    message.success('Section saved successfully');
  };

  const createSectionThumbnail = async (id) => {
    try {
      const section = getSectionById(id);
      if (!section) return null;

      if (isFullWidthImageSection(section)) return null;
      if (containsLargeText(section)) return null;

      const thumbnailUrl = await generateThumbnail(section, id);
      return thumbnailUrl;
    } catch (error) {
      console.error('Error generating section thumbnail:', error);
    }
  };

  const getSectionById = (id) => {
    const section = !isNaN(parseInt(id[0]))
      ? document.getElementById(id)
      : document.querySelector(`#editor-wrapper #${id}`);

    if (!section) {
      console.log(`'${id}' section not full-width.`);
      return null;
    }

    return section;
  };

  const isFullWidthImageSection = (section) => {
    return section?.classList?.value.includes('v3-proposalblock-image');
  };

  const containsLargeText = (section) => {
    const blocks = section.querySelectorAll('.rich-editor');

    for (const block of blocks) {
      const width = block.offsetWidth;
      const spans = block.querySelectorAll('span[data-offset-key]');

      for (const span of spans) {
        const text = span.textContent.split(' ');
        if (text.some((word) => width > 0 && word.length > width / 13)) {
          return true;
        }
      }
    }
    return false;
  };

  const generateThumbnail = async (section, id) => {
    const { fonts } = document;
    const scaleFactor = 400 / section.offsetWidth;

    const canvas = await html2canvas(section, {
      scale: scaleFactor,
      onclone: (document) => {
        fonts.forEach((font) => document.fonts.add(font));
      },
    });

    const blob = await new Promise((resolve) => canvas.toBlob(resolve));
    if (!blob) {
      throw new Error('Error generating image blob.');
    }

    blob.lastModifiedDate = new Date();
    blob.name = `section-${id}-${Date.now()}.png`;

    const { url } = await upload(blob, `thumbnails/sections/${id}/${blob.name}`);
    return url;
  };

  let sections = [];

  if (Object.keys(prop?.draft || {})?.length) {
    const order = prop.draft.sectionorder || helpers.defaultSectionOrder;
    order.forEach((sname, index) => {
      const onElementsInSection = (item, type, key, index) => {
        if (type === 'add') {
          if (
            elementsInSection?.[sname]?.[item]?.blockKeys.includes(key) ||
            elementsInSection?.deletedKeys?.[sname]?.[item]?.includes(key)
          ) {
            return;
          }

          setElementsInSection((prev) => {
            /*
              obj = {
                section-name : {
                  price : { 
                    index : 0, // index of column when price element was added
                    blockKeys : [nru34, kopd45] // block key of the added price element
                  } 
                },
              }
            */

            const newData = { ...prev };

            // create the section level obj
            if (!newData?.[sname]) {
              newData[sname] = {};
            }

            // create the default obj
            if (!newData?.[sname]?.[item]) {
              newData[sname][item] = { index, blockKeys: [] };
            }

            // add the key to array
            if (!newData[sname][item].blockKeys.includes(key)) {
              newData[sname][item].blockKeys.push(key);
            }

            return newData;
          });
        } else {
          setElementsInSection((prev) => {
            const newData = { ...prev };

            // remove the block key
            if (newData[sname][item].blockKeys) {
              newData[sname][item].blockKeys = newData[sname][item].blockKeys.filter(
                (b) => b !== key
              );
            }

            if (newData?.deletedKeys?.[sname]?.[item]) {
              newData.deletedKeys[sname][item].push(key);
            } else {
              newData.deletedKeys = { [sname]: { [item]: [key] } };
            }

            return newData;
          });
        }
      };

      const s =
        prop?.draft[sname]?.text || prop?.draft[sname]?.raw || prop?.draft[sname]?.image ? (
          <SectionWrapper
            key={sname}
            sectionName={sname}
            showDeleteButton={prop?.draft[sname]?.image && !prop?.draft[sname]?.raw ? true : false}
            showColumnsAction={prop?.draft[sname]?.image ? false : true}
            language={prop?.language || 'english'}
            showActionButtons={true}
            showReOrderButtons={true}
            showReOrderUp={index !== 0}
            showReOrderDown={index !== order.length - 1}
            showColumnOptions={showColumnOptions}
            setShowColumnOptions={setShowColumnOptions}
            showAddToLibrary={showAddToLibrary}
            setShowAddToLibrary={setShowAddToLibrary}
            saveSectionToLibrary={saveSectionToLibrary}
            showNewSectionBottomButton={isSection ? false : true}
            showNewSectionTopButton={isSection ? false : true}
            zoomValue={zoomValue}
            onClickItem={onClickSectionWrapperItem}
            updateColumnInSection={updateColumnInSection}
            updateTitlePositionSection={updateTitlePositionSection}
            titlePosition={prop?.draft[sname]?.titlePosition || prop?.draft?.titleFont?.position}
            sectionDroppable={sectionDroppable}
            sectionElementDroppable={sectionElementDroppable}
            isSection={isSection}
            typeOfColumns={prop?.draft[sname]?.typeOfColumns}
            wrapperType={prop?.importedProposal ? 'importProposal' : null}
            isRtl={isRtl}
            elementsInSection={elementsInSection[sname] || {}}
            templateWixEditor={templateWixEditor}>
            <SimpleSection
              key={sname}
              name={sname}
              config={config}
              configText={configText}
              prop={JSON.parse(JSON.stringify(prop))}
              sectionTitlePosition={prop?.draft[sname]?.titlePosition}
              saveUser={saveUser}
              saveProposalDraft={saveProposalDraft}
              setDisablePreview={setDisablePreview}
              setSavingDraftProposal={setSavingDraftProposal}
              element={element}
              elementValue={elementValue}
              setElement={setElement}
              id={sname}
              zoomValue={zoomValue}
              setSelectedBlock={setSelectedBlock}
              updateLoaderState={updateLoaderState}
              // shouldUndoRedoUpdate={shouldUndoRedoUpdate}
              setOpenVariablesList={setOpenVariablesList}
              setScrollingTo={setScrollingTo}
              dropDisabled={dropDisabled}
              setDropDisabled={setDropDisabled}
              draggingElementIndex={draggingElementIndex}
              setDraggingElementIndex={setDraggingElementIndex}
              activeEditorId={activeEditorId}
              setActiveEditorId={setActiveEditorId}
              templateWixEditor={templateWixEditor}
              sectionElementDroppable={sectionElementDroppable}
              draggingElement={draggingElement}
              setElementsInSection={onElementsInSection}
              sectionDroppable={sectionDroppable}
              setLastHoveredSection={setLastHoveredSection}
              askAI={
                (!user?.profile?.enableAITool && prop?.enableAITool) ||
                (user?.profile?.enableAITool && prop?.enableAITool) ||
                wixEditor ||
                templateWixEditor
                  ? askAI
                  : false
              }
              setAskAI={setAskAI}
            />
          </SectionWrapper>
        ) : null;
      if (s) {
        sections.push(s);
      }
    });
    if (order.length !== sectionOrderArrangement.length) {
      setSectionOrderArrangement(order);
    }
  }

  const fontSizeRange = getSmallLargeFont(props?.prop?.draft?.bodyFont?.fontSize);

  if (isLoading || (!prop.draft.signature && !isSection)) {
    return <Loader />;
  }

  return (
    <DraftContext.Provider
      value={{
        collapse,
        collapseContentTable,
        wixProductsAndServices,
        wixSiteProperties,
        fetchMoreWixProductsAndServices,
        fetchMoreWixProductsAndServicesLoading,
        clickCounterRef,
      }}>
      <Layout
        className={`editor 
      ${collapse && !collapseContentTable && 'rich-toolbar-side-menu-collapsed'}
      ${collapse && collapseContentTable && 'rich-toolbar-side-menu-content-table-collapsed'}
      ${collapseContentTable ? 'content-table-collapsed' : 'content-table-active'}
      `}>
        <Layout.Header className="editor-header">
          {wixEditor || templateWixEditor ? (
            <ProposalWixHeaderBar
              mode="draft"
              proposal={JSON.parse(JSON.stringify(prop))}
              user={user}
              saveProposal={saveProposal}
              isSaving={savingDraftProposal}
              openPublishView={openPublishView}
              isTemplate={isTemplate}
              isSection={isSection}
              configText={configText}
              handleManualSave={handleManualSave}
              disablePreview={disablePreview}
              updateLoaderState={updateLoaderState}
              scrollToSection={scrollToSection}
              path={path}
              addItem={addItem}
              setKeyboardModal={setKeyboardModal}
              templateWixEditor={templateWixEditor}
            />
          ) : (
            <ProposalHeaderBar
              mode="draft"
              proposal={JSON.parse(JSON.stringify(prop))}
              user={user}
              saveProposal={saveProposal}
              isSaving={savingDraftProposal}
              openPublishView={openPublishView}
              isTemplate={isTemplate}
              isSection={isSection}
              configText={configText}
              handleManualSave={handleManualSave}
              disablePreview={disablePreview}
              updateLoaderState={updateLoaderState}
              scrollToSection={scrollToSection}
              path={path}
              setKeyboardModal={setKeyboardModal}
            />
          )}
        </Layout.Header>
        <Layout className="editor-layout">
          {!isSection && !prop?.importedProposal && !wixEditor && !templateWixEditor && (
            <ContentTable
              collapse={collapseContentTable}
              setCollapse={setCollapseContentTable}
              proposal={prop}
              scrollToSection={scrollToSection}
              config={config}
              saveProposal={saveProposal}
              path={path}
              showTocMenu={true}
            />
          )}
          <div className="editor-edit-section" id="editor-wrapper">
            <div className="rich-editor-header">
              <div className="rich-editor-toolbar proposal-title">
                {getProposalTitle({ proposal: prop })} (
                {PROPOSAL_STATUS[prop ? prop.state : null] || 'DRAFT'})
              </div>
            </div>
            <Layout.Content className="editor-wrapper">
              <div
                style={{
                  width: 900 * (zoomValue / 100),
                  maxWidth: 900 * (zoomValue / 100),
                  margin: '0 auto',
                }}>
                <div
                  className={`draft-editor ${isRtl ? 'language-rtl' : 'language-english'} 
                  ${sectionDroppable ? 'droppable-editor-notiv' : ''}
                  ${droppable ? 'droppable-editor' : ''}`}
                  style={{
                    width: 900 * (zoomValue / 100),
                    maxWidth: 900 * (zoomValue / 100),
                  }}
                  {...(droppable || coverDroppable
                    ? {
                        onDrop: async (event) => {
                          event.preventDefault();
                          if (event.dataTransfer.getData('text') === 'add-attachment') {
                            showAttachmentModal(true);
                          } else if (
                            event.dataTransfer
                              .getData('text')
                              .startsWith('toolbar-library-attachment-media-')
                          ) {
                            const media = JSON.parse(event.dataTransfer.getData('media'));
                            await addAttachment({
                              variables: {
                                proposalId: prop._id,
                                attachments: [
                                  {
                                    url: await copy({
                                      fromUrl: media.src,
                                      prefix: `props/${prop._id}`,
                                    }),
                                    name: media.name,
                                    type: media.type,
                                    size: 0,
                                  },
                                ],
                                topic: PROPOSAL_SUBSCRIPTION_TOPIC,
                              },
                            });
                          } else if (
                            event.dataTransfer.getData('text').startsWith('toolbar-library-cover-')
                          ) {
                            const img = event.dataTransfer.getData('img');
                            if (img)
                              saveProposal({
                                'draft.header.cover': await copy({
                                  fromUrl: img,
                                  prefix: `props/${prop._id}`,
                                }),
                              });
                          }
                        },
                        onDragOver: (event) => event.preventDefault(),
                      }
                    : {})}>
                  <div
                    className={`draft-editor 
                    ${isRtl ? 'language-rtl' : 'language-english'}
                    ${fontSizeRange}
                    `}
                    style={{
                      width: 900 * (zoomValue / 100),
                      maxWidth: 900 * (zoomValue / 100),
                    }}>
                    {(!isSection || (isSection && isHeader)) && (
                      <SectionWrapper
                        showActionButtons={false}
                        showReOrderButtons={false}
                        showNewSectionBottomButton={isSection ? false : true}
                        showNewSectionTopButton={false}
                        onClickItem={onClickSectionWrapperItem}
                        zoomValue={zoomValue}
                        wrapperType="header"
                        isHeader={isHeader}
                        sectionDroppable={sectionDroppable}
                        isRtl={isRtl}
                        templateWixEditor={templateWixEditor}>
                        <HeaderSection
                          name={isSection ? prop?.draft?.sectionorder[0] || 'header' : 'header'}
                          isSection={isSection}
                          proposal={prop}
                          changeToolBarSideMenu={changeToolBarSideMenu}
                          zoomValue={zoomValue}
                          isSaving={savingDraftProposal}
                          saveProposalDraft={saveProposalDraft}
                          setDisablePreview={setDisablePreview}
                          setSavingDraftProposal={setSavingDraftProposal}
                          saveProposal={saveProposal}
                          saveUser={saveUser}
                          config={config}
                          configText={configText}
                          user={user}
                          isTemplate={path === '/t/:tid' ? true : false}
                          setOpenVariablesList={setOpenVariablesList}
                          setScrollingTo={setScrollingTo}
                          // shouldUndoRedoUpdate={shouldUndoRedoUpdate}
                          onClickItem={onClickSectionWrapperItem}
                          showAddToLibrary={showAddToLibrary}
                          setShowAddToLibrary={setShowAddToLibrary}
                          saveSectionToLibrary={saveSectionToLibrary}
                          coverDroppable={coverDroppable}
                          activeEditorId={activeEditorId}
                          setActiveEditorId={setActiveEditorId}
                          setKeyboardModal={setKeyboardModal}
                          sectionDroppable={sectionDroppable}
                          language={prop?.language}
                          type="header"
                        />
                      </SectionWrapper>
                    )}
                    {sections}
                    {!isSection && (
                      <SectionWrapper
                        showActionButtons={false}
                        showReOrderButtons={false}
                        showNewSectionBottomButton={false}
                        showNewSectionTopButton
                        onClickItem={onClickSectionWrapperItem}
                        zoomValue={zoomValue}
                        wrapperType="signature"
                        sectionDroppable={sectionDroppable}
                        sectionElementDroppable={sectionElementDroppable}
                        isRtl={isRtl}
                        templateWixEditor={templateWixEditor}>
                        <SignatureSection
                          proposal={prop}
                          name="signature"
                          configText={configText}
                          config={config}
                          user={user}
                          saveProposalDraft={saveProposalDraft}
                          setDisablePreview={setDisablePreview}
                          setSavingDraftProposal={setSavingDraftProposal}
                          saveProposal={saveProposal}
                          saveUser={saveUser}
                          updateProposal={updateProposal}
                          setOpenVariablesList={setOpenVariablesList}
                          setScrollingTo={setScrollingTo}
                          // shouldUndoRedoUpdate={shouldUndoRedoUpdate}
                          wixEditor={wixEditor}
                          templateWixEditor={templateWixEditor}
                          activeEditorId={activeEditorId}
                          setActiveEditorId={setActiveEditorId}
                          sectionDroppable={sectionDroppable}
                        />
                      </SectionWrapper>
                    )}

                    {!!prop?.attachments?.length && (
                      <AttachmentSection
                        showAttachmentModal={showAttachmentModal}
                        configText={configText}
                        downloadingFile={downloadingFile}
                        setDownloadingFile={setDownloadingFile}
                        {...props}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Layout.Content>
          </div>
          <ToolBarSideMenu
            proposal={prop}
            sectionRef={librarySectionRef}
            saveProposal={saveProposal}
            activeSideMenuKey={activeSideMenuKey}
            changeToolBarSideMenu={changeToolBarSideMenu}
            handleToolbarElements={handleToolbarElements}
            openVariablesList={openVariablesList}
            setOpenVariablesList={setOpenVariablesList}
            libraryType={libraryType}
            dragStart={dragStart}
            dragEnd={dragEnd}
            collapse={collapse}
            setCollapse={setCollapse}
            showAttachmentModal={showAttachmentModal}
            isSection={isSection}
            addNewSectionFromLibrary={addNewSectionFromLibrary}
            setScrollingTo={setScrollingTo}
            scrollingTo={scrollingTo}
            disablePreview={disablePreview}
            setDisablePreview={setDisablePreview}
            wixEditor={wixEditor}
            templateWixEditor={templateWixEditor}
            user={user}
            language={prop?.language}
          />
        </Layout>
        <DraftModals
          saveProposal={saveProposal}
          scrollToSection={scrollToSection}
          selectedPosition={selectedPosition}
          selectedSectionName={selectedSectionName}
          setSelectedSectionName={setSelectedSectionName}
          importSectionModal={importSectionModal}
          showImportSectionModal={showImportSectionModal}
          confirmModal={confirmModal}
          showConfirmModal={showConfirmModal}
          attachmentModal={attachmentModal}
          showAttachmentModal={showAttachmentModal}
          reOrderSectionModal={reOrderSectionModal}
          showReOrderSectionModal={showReOrderSectionModal}
          downloadingPDF={downloadingPDF}
          downloadPDFError={downloadPDFError}
          setDownloadPDFError={setDownloadPDFError}
          takeOverProposal={takeOverProposal}
          saveLastSectionOrder={saveLastSectionOrder}
          setSectionOrderArrangement={setSectionOrderArrangement}
          wixEditor={wixEditor}
          templateWixEditor={templateWixEditor}
          setOpenWixPreview={setOpenWixPreview}
          keyboardModal={keyboardModal}
          setKeyboardModal={setKeyboardModal}
          isTemplate={isTemplate}
        />
        <div className={`zoom-container ${!collapseContentTable ? 'zoom-container-expanded' : ''}`}>
          <ZoomHandler setZoom={setProposalZoom} />
        </div>

        {copyStatus && <Spin className="rich-copy-spinner" size="large" />}
        {(showLoaderProp || downloadingFile) && <Loader overlay={true} />}
      </Layout>
    </DraftContext.Provider>
  );
};

Draft.defaultProps = {
  config: '',
  prop: '',
  user: '',
  saveProposalDraft: () => {},
  saveProposal: () => {},
  // handleUndoRedo: () => {},
  isTemplate: false,
  isSection: false,
  // shouldUndoRedoUpdate: false,
  fetchMoreWixProductsAndServicesLoading: false,
  fetchMoreWixProductsAndServices: () => {},
  configText: () => {},
  templateWixEditor: false,
  openWixPreview: false,
};

Draft.propTypes = {
  config: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  prop: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  saveProposalDraft: PropTypes.func,
  saveProposal: PropTypes.func,
  // handleUndoRedo: PropTypes.func,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  isTemplate: PropTypes.bool,
  isSection: PropTypes.bool,
  // shouldUndoRedoUpdate: PropTypes.bool,
  fetchMoreWixProductsAndServicesLoading: PropTypes.bool,
  fetchMoreWixProductsAndServices: PropTypes.func,
  configText: PropTypes.func.isRequired,
  templateWixEditor: PropTypes.bool,
  openWixPreview: PropTypes.bool,
};

export default Draft;

const getSmallLargeFont = (fontSize) => {
  fontSize = parseInt(fontSize);

  if (!fontSize || isNaN(fontSize)) {
    return '';
  }

  if (fontSize <= 12) {
    return 'small-font';
  }
  if (fontSize >= 36) {
    return 'max-font';
  }
  if (fontSize >= 24) {
    return 'large-font';
  }

  return '';
};
